<template>
   <div class="col blokvijesti" @click="$emit('clickNaNovost')">
    <h4>{{ naslov }}</h4>
    <h5 class="datum">{{ datum }}</h5>
    <p v-html="skraceno"></p>
    <button class="btn btn-primary"  @click="$emit('clickNaNovost')">nastavak</button>
    </div>
</template>

<script>

export default {
   props:['naslov','datum','skraceno','id'],
   emits: ['clickNaNovost'],
       
  data(){

    return{
        
       
    }
  },

  
  mounted() {
   // console.table(this.novosti)



  }

}

</script>
<style>
.blokvijesti{
  
    display:inline-block;
    -moz-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    background: #fff; 
    border:1px solid #FFF;
    padding: 20px;
}

h5.datum {font-size:13px;color:#999}
.blokvijesti:hover{background:#f9f9f4;cursor:pointer;border:1px solid #d9d9d9}
.blokvijesti{min-width:400px;margin:2px;margin-bottom:14px}



</style>