let timer; // for holding timer function for auto logout if session expire

export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },

  async auth(context, payload) {
    //const token = context.rootGetters.token; //User token !!

    const baseUrl = localStorage.getItem("_rootRestUrl");
    const response = await fetch(baseUrl + "login.php", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: payload.username,
        password: payload.password,
        sessionId: "",
      }),
    });

    /* if (response.status==404) {  
       
        alert("Wrong username or password!");
       // parent.isLoading = false;
    }*/

    if (response.status != 200) {
      const responseData2 = await response.text();

      // console.log(responseData);
      const error = new Error(responseData2);
      throw error;
    }

    const responseData = await response.json();

    //  console.table(responseData);

    //expecting in json recive 'exipresIn' in minutes! how long will session exists
    //const expiresIn = responseData.validFor * 1000 * 60;
    const expiresIn = 1000 * 60 * 15;

    //auto logout if token expires
    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    //store login details in local storeage so that on refresh page app remember loged user!

    try {
      localStorage.setItem("token", responseData[0]["sessionId"]);
      localStorage.setItem("slika", responseData[0]["slika"]);
      localStorage.setItem("userId", responseData[0]["id"]);
      localStorage.setItem("useruuId", responseData[0]["uuid"]);
      localStorage.setItem(
        "userFullname",
        responseData[0]["name"] + " " + responseData[0]["surname"]
      );

      context.commit("setUser", {
        token: responseData[0]["sessionId"],
        userId: responseData[0]["id"],
        useruuId: responseData[0]["uuid"],
        userFullname:
          responseData[0]["name"] + " " + responseData[0]["surname"],
        userSlika: responseData[0]["slika"],
      });
    } catch (e) {
      alert("Pogrešno korisničko ime i/ili lozinka");
    }
  },

  logout(context) {
    // const thistoken = localStorage.getItem("token"); // needed for logout

    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("useruuId");
    localStorage.removeItem("userFullname");
    localStorage.removeItem("slika");

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      useruuId: null,
      userId: null,
      userFullname: null,
      userType: null,
      userSlika: null,
      tokenExpiration: null,
    });

    //send logout request :
    //    const baseUrl = localStorage.getItem("_rootRestUrl");

    /*    fetch(baseUrl+"/api/v1/Auth/LogOut", {
          method: "POST",

          headers: {
            "Content-Type":"application/json"
          },
          body: JSON.stringify({
            email: '',
            password: '',
            sessionId: thistoken,
          }),
        });*/

    // ------------
  },

  //try to login if local storage containt login details
  autoLogin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const useruuId = localStorage.getItem("useruuId");
    const userFullname = localStorage.getItem("userFullname");
    const userSlika = localStorage.getItem("slika");

    const expiresIn = 1000 * 60 * 15;

    // alert('autologin')
    /*  if (willExpire < 0) {
      //if token expires do nothing
      return;
    }*/

    // alert("token ok")

    //if is token ok then we must extend life of the token for the next expiration time

    const expirationDate = new Date().getTime() + expiresIn;
    localStorage.setItem("tokenExpiration", expirationDate);

    clearTimeout(timer);

    //set new timer
    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    //login "again" this user
    if (token) {
      context.commit("setUser", {
        token: token,
        userId: userId,
        useruuId: useruuId,
        userType: null,
        userSlika: userSlika,
        userFullname: userFullname,
      });
    }
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
    location.reload();
    this.$router.push("/");
  },
};
