export default {
  userId(state) {
    return state.userId;
  },
  useruuId(state) {
    return state.useruuId;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return !!state.token
  },
  getUserFullName(state){
    return state.userFullname;
  },
  getUserType(state){
    return state.userType;
  },
  didAutoLogout(state){
    return state.didAutoLogout;
  },
  getUserSlika(state){
    return state.userSlika;
  }

};
