import { createApp, defineAsyncComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import App from "./App.vue";
import mitt from 'mitt';

import PrimeVue,  { defaultOptions } from 'primevue/config';
import 'primevue/resources/themes/lara-dark-blue/theme.css';

const emitter = mitt();

// static pages allways loaded
import IndexPage from "./components/IndexPage.vue";

//async pages, loaded only when needed! not in advance
const PagesHolder = () => import("./components/PagesHolder.vue");

//--Transport pages
//const TransportTrips= () => import("./components/pages/transport/TransportTrips.vue");
//const ManageUsers= ()    => import("./components/pages/transport/ManageUsers.vue");

//--Password recovery pages
const ResetPassword= ()    => import("./components/pages/ResetPassword.vue");
const ForgotPassword= ()    => import("./components/pages/ForgotPassword.vue");

//includable parts of the page
import TopBar from "./components/include/TopBar.vue";
import skraceneNovosti from "./components/skraceneNovosti.vue";


/// some UI
import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import SearchHolder from "./components/ui/SearchHolder.vue";
import SidebarmenuLink from "./components/ui/SidebarMenuLink.vue";
import SidebarMenuLinkDirect from "./components/ui/SidebarMenuLinkDirect.vue";
import Multiselect from '@vueform/multiselect'; //multi select userguide: https://github.com/vueform/multiselect
import Tooltip from 'primevue/tooltip';
import VueImageZoomer from 'vue-image-zoomer'; // https://github.com/samjonesigd/vue-image-zoomer
import 'vue-image-zoomer/dist/style.css'; 

import blockPiramide from "./components/blockPiramide.vue";

import VueCryptojs from 'vue-cryptojs'


// block of code sometimes loaded
const ContactInfo = defineAsyncComponent(() =>
  import("./components/ui/ContactInfo.vue")
);
const VersionInfo = defineAsyncComponent(() =>
  import("./components/ui/VersionInfo.vue")
);

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
   /* console.log(to);
    console.log(from);
    console.log(savedPosition)*/
    return { top: 0 }
  },


  history: createWebHistory(""),
  routes: [
    { path: "/", component: IndexPage, meta: { requiresUnAuth: true } },
     
       {
          path: "/resetPassword/:resetKey",
          component: ResetPassword,
          name:"ResetPassword",
          props:true,
          meta: { requiresAuth: false }
        },

        {
          path: "/forgotPassword",
          component: ForgotPassword,
          name:"ForgotPassword",
          meta: { requiresAuth: false }
        },
    
    {
    
    
      path: "/pages",
      component: PagesHolder,
      redirect: "/",

      children: [    
                 
      

        //-- end pages
       
      ],
    },
  ],
});




/*router.beforeEach(function (to, from, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/");
  } else {
    store.dispatch("autoLogin");
    // next();
  }

  if (to.meta.requiresUnAuth && store.getters.isAuthenticated) {
    next("/");
  } else {
    store.dispatch("autoLogin");
    next();
  }
});*/

const app = createApp(App);
  import store from "./store/index.js";

  app.config.globalProperties.emitter = emitter;



//global variables
app.config.globalProperties._rootVersion = process.env.VUE_APP_VERSION; // get From package.json
//-- more variables loaded from /settings.json


axios.get("/settings.json").then((resp) => {
  var settings = resp.data;
  var obj = settings[0];

  Object.keys(obj).forEach(function (key) {
    var value = obj[key];
    
    //global variables read from /settings.json file!!
    if (key == "RestUrl") {
      app.config.globalProperties._rootRestUrl = value;
      localStorage.setItem('_rootRestUrl',value);
    }

    if (key == "AppTitle") {
      app.config.globalProperties._rootAppTitle = value;
    }
    if (key == "DefaultProjectCard") {
      app.config.globalProperties._rootDefaultProjectCard = value;
    }
    if (key == "LoginBackground") {
      app.config.globalProperties._rootLoginBackground = value;
    }
    if (key == "LoginLogo") {
      app.config.globalProperties._rootLoginLogo = value;
    }
    if (key == "LoginLogoWhite") {
      app.config.globalProperties._rootLoginLogoWhite = value;
    }
    if (key == "LinkToHelpFile") {
      app.config.globalProperties._rootlinkToHelpFile = value;
    }
    if (key == "LinkToUpdateHistory") {
      app.config.globalProperties._rootLinkToUpdateHistory = value;
    }



  });



  app.use(router);
  app.use(store);

  app.use(PrimeVue, {
    // your configuration
    locale: {
      ...defaultOptions.locale, 
        monthNames:['Siječanj','Veljača','Ožujak','Travanj','Svibanj','Lipanj','Srpanj','Kolovoz','Rujan','Listopad','Studeni','Prosinac'],
        dayNamesMin:['Ned','Pon','Uto','Sri','Čet','Pet','Sub'],
        firstDayOfWeek: 1,        // change to Monday
    },
});
  app.use(VueCryptojs);

  
  app.use(VueImageZoomer);

  app.component("top-bar", TopBar);
  app.component("skracene-novosti", skraceneNovosti);

  app.component("index-page", IndexPage);

  app.component("version-info", VersionInfo);
  app.component("contact-info", ContactInfo);
  app.component("search-holder", SearchHolder);
  app.component("base-spinner", BaseSpinner);
  app.component("base-dialog", BaseDialog);
  app.component("menu-link", SidebarmenuLink);
  app.component("menu-linkdirect", SidebarMenuLinkDirect);
  app.component("v-select", Multiselect);

  app.component("blockPiramide", blockPiramide);

  app.directive('tooltip', Tooltip);


  app.mount("#app");
 });

// -------------------
