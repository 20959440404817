import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    state() {
        return {
          userId: null,
          useruuId: null,
          token: null,
          userSlika: null,
          didAutoLogout : false
        };
      },
      mutations,
      actions,
      getters

}