<template>
  <base-dialog :show="!!error" title="Error"></base-dialog>
  <base-dialog :show="isLoading" title="Authenticating..." fixed>
    <base-spinner></base-spinner>
  </base-dialog>

  <div id="hereMoveTopBar"></div>


  <div class="containerfluid loginPageBackground" :style="{ backgroundImage: `url(${_rootLoginBackground})` }">


   <div class="container pagesHolder">
    <div class="row" style="background:white">
      <div class="col-md-12" id="sve">
        <router-view></router-view>
      </div>
    </div>
   </div>      
   
  
  </div>

  <!-- Login Modal -->
  <div
    class="modal fade"
    id="loginModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">Prijava igrača</h5>
          <button
            type="button"
            id="closeLoginModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="Inputusername" class="form-label">Korisničko ime</label>
              <input
                type="username"
                v-model="username"
                @keyup="usernameKeyDown"
                class="form-control"
                id="Inputusername"
                aria-describedby="usernameHelp"
              />
              <div v-if="showUsernameAlert" class="alert alert-danger alert-sm">
                {{ usernameMessage }}
              </div>
            </div>
            <div class="mb-3">
              <label for="InputPassword" class="form-label">Lozinka</label>
              <input
                type="password"
                v-model="password"
                @keyup="passwordKeyDown"
                class="form-control"
                id="InputPassword"
              />
              <div v-if="showPasswordAlert" class="alert alert-danger alert-sm">
                {{ passwordMessage }}
              </div>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="Check1" />
              <label class="form-check-label" for="Check1">Remember me</label>
            </div>
            <button type="submit" class="btn btn-primary" @click="logiraj">Login</button>
          </form>


        </div>
        <div class="modal-footer">
          <a href="/forgotPassword">Forgot password?</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
//visual settings:
var onLoadSidebarWidth = 200;
var breakPointForSmallScreen = 750;
var minWSidebarWidth = 38;

var breakPointHideSidebarDescriptions = 150;
var maxPercentageSidebarWidth = 30;
var onLoadContentWidth = window.innerWidth - onLoadSidebarWidth;
*/


export default {
  
  data() {
    return {
       defaultLoginBackground: 'images/login-background.jpg', 
       mainAppTitle : 'Transport',
       isLoading:false,
       error:false,
      showMenu:false,
      showSearch:false,
      MainTitleText:'',
      showUsernameAlert:false,
      showPasswordAlert:false,
    
       //ini settings
      linkToHelpFile : '',
      username:'',
      password:'',
      // for visual settings:
      
      padding0:'0px',
      dividerPosition: 200, //px default width of the left sidebar!
      contentWidth: (window.innerWidth - 200), //px width of the main content
      isActive: true,       //in start visible sidebar icon descriptions
      isCentered: false,     //sidebar icons on left at page load
      iconCloseOpen: '&times;',
    };
  },

  methods: {
    async submitForm() {
    /*
      this.showUsernameAlert = false;
      this.showPasswordAlert = false;
      this.formIsValid = true;
      this.usernameMessage = "";
      this.passwordMessage = "";

      if (this.username === "") {
        this.showUsernameAlert = true;
        this.formIsValid = false;
        this.usernameMessage = "Please enter username";
      }

      if (this.password.length < 4) {
        this.showPasswordAlert = true;
        this.formIsValid = false;
        this.passwordMessage = "Password is too short";
      }

      if (this.password === "") {
        this.showPasswordAlert = true;
        this.formIsValid = false;
        this.passwordMessage = "Please enter password";
      }

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;
      this.closeModal("closeLoginModal"); //close login modal after submit;

      try {
        await this.$store.dispatch("login", {
          username: this.username,
          password: this.password,
        });

        this.isLoading = false;
        this.closeModal("closeLoginModal");
        this.$router.replace("/");
      
    
    } catch (e) {

        this.closeModal("closeLoginModal"); //close login modal after submit;
        this.error = e.message || "Failed to authenticate.";

        alert(this.error);

        this.error = null;

        this.isLoading = false;
      }

      this.isLoading = false;*/
    },
 
    closeModal(id) {
      document.getElementById(id).click();
    },
    
  async   logiraj(){
      this.showUsernameAlert = false;
      this.showPasswordAlert = false;
      this.formIsValid = true;
      this.usernameMessage = "";
      this.passwordMessage = "";

      if (this.username === "") {
        this.showUsernameAlert = true;
        this.formIsValid = false;
        this.usernameMessage = "Please enter username";
      }

    /*  if (this.password.length < 4) {
        this.showPasswordAlert = true;
        this.formIsValid = false;
        this.passwordMessage = "Password is too short";
      }*/

      if (this.password === "") {
        this.showPasswordAlert = true;
        this.formIsValid = false;
        this.passwordMessage = "Please enter password";
      }

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;
    //  this.closeModal("closeLoginModal"); //close login modal after submit;

      try {
        await this.$store.dispatch("login", {
          username: this.username,
          password: this.password,
        });

        this.isLoading = false;
        this.closeModal("closeLoginModal");
     


     //   this.$router.replace("/");
     location.reload();
    
    } catch (e) {

      //  this.closeModal("closeLoginModal"); //close login modal after submit;
        this.error = e.message || "Failed to authenticate.";

        alert(this.error);

        this.error = null;

        this.isLoading = false;
      }

      this.isLoading = false;
    },


    /// ------------------------------
    /// resizable left sidebar!
    handleDragging(e) {
      
      const percentage = (e.pageX / window.innerWidth) * 100;

      if ( (e.pageX+1) > 38 && percentage <= 30) {
        //only resizable if width of the left sidebar is in this percentage range
        this.dividerPosition = (percentage / 100) * window.innerWidth;
        this.contentWidth = window.innerWidth - this.dividerPosition;
      }
      
      // on small screens hide sidebar menu descriptions
      if (e.pageX < 150) {
        this.isActive = false;
        this.iconCloseOpen='&#9776;'
        this.isCentered=true;
      } else {
        this.isActive = true;
        this.iconCloseOpen='&times;'
        this.isCentered=false;
      }
      // -------------------
    },
    startDragging() {
        document.addEventListener("mousemove", this.handleDragging);
    },
    endDragging() {
      document.removeEventListener("mousemove", this.handleDragging);
    },

   
    displayMenuToggle(){
     
       this.showMenu = true;
       //this.getDimensions() ;

       this.padding0='12px';
       this.dividerPosition= 200; //px default width of the left sidebar!
       this.contentWidth= (window.innerWidth - 200); //px width of the main content
    },
    setToFullScreenWidth(){
     
      this.contentWidth = window.innerWidth // login page has full screen width without sidebar
      this.padding0 = '0px';
    },

    getDimensions() {
      //on window resize
    
      if (window.innerWidth > 750) {
        this.dividerPosition = 200;
        this.contentWidth = window.innerWidth - this.dividerPosition;
        this.isActive = true;
        this.iconCloseOpen= '&times;'
        this.isCentered=false;
       
      } else {
       
        this.dividerPosition = 38;
        this.isActive = false;
        this.iconCloseOpen='&#9776;'
        this.isCentered=true;
      }
     
    },
    sidebarColapse() {
      
      this.isActive = !this.isActive;
      this.isCentered= !this.isActive;
      
      if (this.dividerPosition > 38) {
        this.dividerPosition = 38;
        this.contentWidth = window.innerWidth - this.dividerPosition;
        this.iconCloseOpen='&#9776;'
      } else {
        this.dividerPosition = 200;
        this.contentWidth = window.innerWidth - this.dividerPosition;
        this.iconCloseOpen= '&times;'
    
      }
    },

    manualLogout(){
       this.$store.dispatch('logout');
       this.showMenu = false;
       this.$router.replace('/');
    },
    extendSession(){
      this.$store.dispatch('autoLogin');

    }
    

    // ----------- end resizable left menu -------
  },

  mounted() {
   
    window.addEventListener("resize", this.getDimensions);
    window.addEventListener("click", this.extendSession);

  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  created() {
  
    //check if user is loggen in
    this.$store.dispatch('autoLogin');

    
    
    //on create (on load)
    // this.projects = projectsObject; // parsed json

    //hidden sidebar on load if small screen
    if (window.innerWidth < 750) {
      this.dividerPosition = 38;
    }

    if (window.innerWidth < 750) {
      this.isActive = false;
      this.iconCloseOpen='&#9776;';
      this.isCentered=true;
    }else{
      this.isActive = true;
      this.iconCloseOpen='&times;';
      this.isCentered=false;
    }
  },
  computed:{
    isLoggedIn(){

      return this.$store.getters.isAuthenticated;
    },
     userFullname(){
      return this.$store.getters.getUserFullName;
    },
    didAutoLogout(){
      return this.$store.getters.didAutoLogout;
    }
  },

  watch: {
    /*didAutoLogout(curValue, oldValue){
        if(curValue && curValue !== oldValue){
           this.showMenu = false;
           this.$router.replace('/');
        }
    }*/
  }



};
</script>
<style>
#sve{height:calc(100vh - 55px) ;overflow-y: auto;padding-top:6px;}
</style>