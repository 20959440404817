export default {
  setUser(state, payload) {
    state.token = payload.token;
    state.userId = payload.userId;
    state.useruuId = payload.useruuId;
    state.userFullname = payload.userFullname;
    state.didAutoLogout = false;
    state.userSlika = payload.userSlika;
  
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
};
