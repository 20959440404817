<template>
  <div
    class="topLoginBar"
    as="as"
    :style="{ background: `${backgroundStyle}` }"
  >
    <div class="container-fluid" style="position: relative">
      <div class="row align-items-center topLoginBarRow">
        <div class="col-6" v-if="!this.$store.getters.isAuthenticated">
          <img :src="_rootLoginLogoWhite" class="imf-fluid"  style="height:60px;margin-bottom:8px;margin-left: 13px; max-width: calc(100% - 70px)"/>
        </div>

        <div
          class="col-6"
          v-if="this.$store.getters.isAuthenticated"
          style="padding-left: 0"
        >
          
          <img
            :src="_rootLoginLogo"
            style="height:60px;margin-bottom:8px;margin-left: 13px; max-width: calc(100% - 70px)"
          />
        </div>

      

      

        <!-- if user not logged in -->
        <div
          class="col-6 text-end"
          v-if="!this.$store.getters.isAuthenticated"
        >
          <a
            href="javascript:;"
            class="signInHref"
            data-bs-toggle="modal"
            data-bs-target="#loginModal"
          >
            <span class="">
              Prijava
              <i class="fa-solid fa-user" style="margin-left: 6px"></i>
            </span>
            <!-- <span class="d-block d-sm-none">
               <i class="fa-solid fa-bars"></i
            ></span>-->
          </a>
        </div>

        <!-- if user  logged in -->
        <div
          class="col-6 text-end"
          v-if="this.$store.getters.isAuthenticated"
        >
          <div style="">
            
            <div class="text-in-circle">
              <div class="dropdown topUserDropdownMenu">
                <p
                  class="text-center dropdown-toggle"
                  data-bs-toggle="dropdown"
                  title="User setting"
                >
                  {{ userInitials() }}
                </p>

                <div
                  class="dropdown-menu w300 dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                >
                  <div
                    class="row align-items-center"
                    style="margin: 0 !important"
                  >
                    <div class="col">
                      
                    
                      <img :src="userovaSlika" style="max-height:80px">

                    </div>

                  </div>
                    <div
                    class="row align-items-center"
                    style="margin: 0 !important"
                  >
                    <div class="col">
                      <div class="row">
                        <div class="col-12">
                          <strong>{{ this.$store.getters.getUserFullName }}</strong>
                        </div>
                        <div class="col-12">
                         
                         <!--- <a
                            class="mOver small"
                            href="/identity"
                            target="_blank"
                            >Manage your DFM account</a
                          >
                         -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <a class="dropdown-item" href="javascript:;" @click="dajMiSvePodatkeIgraca"
                    ><i class="pi pi-user-edit" ></i>Promjena podataka</a
                  >
                  <a class="dropdown-item" href="javascript:;" @click="odgodaIgranja"
                    ><i class="pi pi-history" ></i>Odgoda igranja</a
                  >

                  <hr />
                  <a class="dropdown-item" href="" @click="signoutClick"
                    ><i class="fa-solid fa-arrow-right-from-bracket blueIcons" ></i> Odjava</a
                  >
                
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- About Modal -->
  <div
    class="modal fade"
    id="aboutModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">About</h5>
          <button
            type="button"
            id="closeaboutModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Application: <strong>{{ _rootAppTitle }}</strong>
          </p>
          <p>
            Version: <strong>{{ _rootVersion }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>


  <!-- dialog promjena podataka --> 
  <Dialog v-model:visible="displayDialogPromjenePodataka" modal  header="Promjena podataka" :style="{ width: '50rem' }"  :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
  
    <div class="row">
          <div class="col-sm-5 col-md-5 col-12" style="padding:0">
            <img class="slikaigraca" :src="URLSLIKEIGRACA" style="max-height:400px">
          
                  <br><br>
        
                  <FileUpload class="btn btn-primary" chooseLabel="Pošalji novu sliku"  withCredentials="true" mode="basic" auto url="#"  @select="uploadSlike($event)" name="fileField" accept="image/*" :maxFileSize="10000000"  />
                  <br><br>




          </div>
         
          <div class="col-sm-7 col-md-7 col-12" style="padding:0">
           
            <h4>Podaci igrača</h4>
              <div class="row">
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="ImeIgraca" v-model="ImeIgraca" class="with100posto"/>
                        <label for="ImeIgraca">Ime</label>
                    </FloatLabel>
                  </div>
             

             
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="PrezimeIgraca" v-model="PrezimeIgraca" class="with100posto"/>
                        <label for="PrezimeIgraca">Prezime</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="GodinaIgraca" v-model="GodinaIgraca" class="with100posto"/>
                        <label for="GodinaIgraca">Godina rođenja</label>
                    </FloatLabel>
                  </div>
             
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="TelefonIgraca" v-model="TelefonIgraca" class="with100posto"/>
                        <label for="TelefonIgraca">Telefon</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="EmailIgraca" v-model="EmailIgraca" class="with100posto"/>
                        <label for="EmailIgraca">Email</label>
                    </FloatLabel>
                  </div>
             
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="UlicaIgraca" v-model="UlicaIgraca" class="with100posto"/>
                        <label for="UlicaIgraca">Ulica i broj</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="PostanskiIgraca" v-model="PostanskiIgraca" class="with100posto"/>
                        <label for="PostanskiIgraca">Poštanski broj</label>
                    </FloatLabel>
                  </div>
             
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="GradIgraca" v-model="GradIgraca" class="with100posto"/>
                        <label for="GradIgraca">Grad</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="DrzavaIgraca" v-model="DrzavaIgraca" class="with100posto"/>
                        <label for="DrzavaIgraca">Država</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <Dropdown v-model="SpolIgraca" class="with100posto" :options="spolovi" optionLabel="name"  optionValue="value" checkmark :highlightOnSelect="true" />
                        <label for="SpolIgraca">Spol</label>
                    </FloatLabel>
                  </div>
             
                  <div class="col-md-6 razmakDolje">
                     <FloatLabel>
                        <InputText id="KlubIgraca" v-model="KlubIgraca" class="with100posto"/>
                        <label for="KlubIgraca">Klub/Klubovi</label>
                    </FloatLabel>
                  </div>
              </div>

              
              <div class="row">
                  <div class="col razmakDolje">
                    <Button class="btn btn-primary" @click="promjenaPodatakaIgraca">Spremi promjene</Button>
                     </div>
              </div>


              <hr>

              <h4>Podaci za prijavu</h4>
              <div class="row">
                  <div class="col razmakDolje">
                     <FloatLabel>
                        <InputText id="unigraca" v-model="unigraca" disabled class="with100posto"/>
                        <label for="unigraca">Korisničko ime</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col razmakDolje">
                     <FloatLabel>
                        
                         <Password v-model="psigraca" toggleMask />
                         <label for="psigraca">Nova lozinka</label>
                    </FloatLabel>
                  </div>
              </div>

              <div class="row">
                  <div class="col razmakDolje">
                    <Button class="btn btn-primary"  @click="promjenaLozinke">Promjena lozinke</Button>
                     </div>
              </div>


          
          </div>
            
   </div>

                      
       
</Dialog>


  <!-- dialog odgode --> 
  <Dialog v-model:visible="displayDialogOdgode" modal  header="Odgoda igranja" :style="{ width: '30rem' }"  :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
  
          
          <span class="font-bold text-2xl block mb-2 mt-4"></span>
          <p class="mb-0">Odaberite raspon datuma kada ne možete igrati:</p>
          
          <Calendar @date-select="odabraoDatume"  hideOnRangeSelection="true" showIcon="true" v-model="datumi" :minDate="minDate" selectionMode="range" :numberOfMonths="2"  :manualInput="false" dateFormat="dd/mm/yy"/>

          <p v-if="kolikoJeIskoristio>0" style="margin-top:20px" class="mb-0">Dosad ste iskoristili <b>{{ kolikoJeIskoristio }}</b> od dopuštenih <b>{{ kolikoJeDopusteno }}</b> odgoda.</p>
          <p v-if="kolikoJeIskoristio==0" style="margin-top:20px" class="mb-0">Imate pravo na <b>{{ kolikoJeDopusteno }}</b> {{ rjecnikDopustnihOdgova}}.</p>


          <div class="row" style="margin-top:20px">
            <div class="col-md-6">
              <Button class="btn btn-danger" label="Otkaži" raised  @click="displayDialogOdgode=false"></Button>
            </div>
            <div class="col-md-6 text-end ">
              <Button class="btn btn-primary" v-if="okOdabraoDatume" label="Odgodi igranje" raised @click="odgodiIgranje" ></Button>
            </div>
          </div>
                              
               
</Dialog>

  <!-- dialog odgode --> 
  <Dialog v-model:visible="displayDialogOdgodeGreska" modal  header="Greška" :style="{ width: '30rem' }"  :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
  
          
      <span class="font-bold text-2xl block mb-2 mt-4"></span>
      <p class="mb-0">Ne možete odgoditi igranje jer ste već iskoristili <strong>{{ mozeOdgoditi['maks'] }}</strong> dopuštene odgode igranja!</p>
      
      <div class="row" style="margin-top:20px">
      
        <div class="col-md-6 text-end ">
          <Button label="OK" raised  @click="displayDialogOdgodeGreska=false"></Button>
        </div>
      </div>
                      
       
</Dialog>

  <!-- dialog odgode greška odabira datuma--> 
  <Dialog v-model:visible="displayDialogOdgodeGreskaOdabira" modal header="Greška" :style="{ width: '30rem' }"  :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
  
          
  <span class="font-bold text-2xl block mb-2 mt-4"></span>
  <p class="mb-0">Možete odgoditi igranje na minimalno <b>{{ minimalniBrojDanaOdgode }} </b>, a maksimalno <b>{{ maksimalniBrojDanaOdgode }} </b> dana!</p>
  
  <div class="row" style="margin-top:20px">
  
    <div class="col-md-12 text-end ">
      <Button label="OK" raised  @click="displayDialogOdgodeGreskaOdabira=false"></Button>
    </div>
  </div>
                  
   
</Dialog>

  <!-- dialog info --> 
  <Dialog v-model:visible="displayInfoDialog" modal  :style="infoDialogStyle" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header=""> 
        <h4> {{ InfoModalHeader }}</h4>
    </template>
  
            <span class="font-bold text-2xl block mb-2 mt-4"></span>
            <p class="mb-0">{{InfoModalMessage }}</p>
            <div class="flex text-end mt-4">
                <Button label="Ok" @click="displayInfoDialog=false"></Button>
            </div>          
</Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import axios from "axios";
import moment from "moment";
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import Password from 'primevue/password';
import FileUpload from 'primevue/fileupload';
import Dropdown from 'primevue/dropdown';

import CryptoJS from 'crypto-js';



export default {
  components: {
        //DataTable,
        //Column,
      
         InputText,
         FloatLabel,
         Password,
         FileUpload,
        // Checkbox,
            Button,
            Dialog,
            Calendar,
        //InputNumber,
        //TabView,
        //TabPanel,
        Dropdown
  },
  props: [
    "backgroundStyle",
    "titleColor",
    "titleText",
    "menuIcon",
    "showSearch",
    "emitSearch",
  ],

  data() {
    return {
     ImeIgraca:'',
     PrezimeIgraca:'',
     TelefonIgraca:'',
     EmailIgraca:'',
     psigraca:'',
     unigraca:'',
     SpolIgraca:'',
     PostanskiIgraca:'',
     UlicaIgraca:'',
     GradIgraca:'',
     DrzavaIgraca:'',
     GodinaIgraca:'',
     sviPodaciIgraca:'',
     KlubIgraca:'',
     URLSLIKEIGRACA:'',
     spolovi:[
          { name: 'Muški', value: 'm' },
          { name: 'Žensko', value: 'ž' }
      ],

      RESTURL:localStorage.getItem("_rootRestUrl"),

      minimalniBrojDanaOdgode: 7, // MINIMALNO koliko se može odgoditi igranje !
      maksimalniBrojDanaOdgode: 30, // MAKSIMLANO koliko se može odgoditi igranje !

      rjecnikDopustnihOdgova:'odgoda',

      displayDialogPromjenePodataka:false,
     

      DOMENA:'https://piramida.it-b.hr/',

      thisUserUuid : localStorage.getItem('useruuId'),
      kolikoJeIskoristio:0,
      kolikoJeDopusteno:0,
      userovaSlika:'',
      displayDialogOdgodeGreska:false,
      displayDialogOdgode:false,
      datumi:[],
      minDate: '',
      okOdabraoDatume:false,
      displayDialogOdgodeGreskaOdabira:false,
      displayInfoDialog:false,
      InfoModalMessage:'',
      InfoModalHeader:'',
    }
    
  },
  mounted() {
 
    //'https://www.it-b.hr/piramida/slike/'+$store.getters.getUserSlika 
    if(this.$store.getters.getUserSlika != ''){
      this.userovaSlika = this.DOMENA+'slike/'+this.$store.getters.getUserSlika ;
    }


    //minimum datum za odgabrati odgodu (danas)
    
          var mjesec = moment().month();
          var godina  =moment().year();
          var dan  = moment().date();

          if(mjesec<10){mjesec='0'+mjesec;}
          if(dan<10){dan='0'+dan;}
        
          this.minDate = godina+"-"+mjesec+"-"+dan;
          this.minDate = new Date(godina,mjesec,dan);
    


  },
  methods: {
    userInitials() {
     /* const fullName = this.$store.getters.getUserFullName;
      return fullName
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();*/

        let name = this.$store.getters.getUserFullName;
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
              (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;

    },
    clickOnHelp() {
    
      window.open(this._rootlinkToHelpFile, "_blank");
    },
    signoutClick() {
      this.$store.dispatch("logout");
    },

    promjenaPodataka(){
   
    },

    dajPopupOdgode(){
        if(this.mozeOdgoditi['mozeOdgoditi']=='true'){
          //može odgoditi
          this.displayDialogOdgode = true;
          this.okOdabraoDatume = false;
          this.datumi=[];

          this.kolikoJeDopusteno = this.mozeOdgoditi['maks'];
          this.kolikoJeIskoristio = this.mozeOdgoditi['maks'] - this.mozeOdgoditi['josMoze'];

          if (this.kolikoJeDopusteno == 1){this.rjecnikDopustnihOdgova='odgodu';}
          if (this.kolikoJeDopusteno > 1 & this.kolikoJeIskoristio < 4){this.rjecnikDopustnihOdgova='odgode';}
          if (this.kolikoJeDopusteno > 4){this.rjecnikDopustnihOdgova='odgoda';}

        }else{
          //ne može odgoditi, ima previše odgoda
          this.displayDialogOdgodeGreska = true;
        }

    },

   
async    odgodaIgranja(){

          //provjeriti jel može odgoditi


           
          const baseUrl = localStorage.getItem("_rootRestUrl");

                let formData = JSON.stringify({"piramida":this.piramidaID});

                let config = {
                  headers: {
                    "Content-Type": "application/json",
                }};

                await axios
                .get(baseUrl + "provjera-odgode-kola.php?uuid="+this.$store.getters.useruuId,formData, config )
                .then((response) => {
                                  
                  this.mozeOdgoditi = response.data; 
                   
                  this.dajPopupOdgode();  
                
                })

                .catch((error) => {

                      if(error.response){
                          this.InfoModalHeader='Info';
                          this.InfoModalMessage='Došlo je do pogreške prilikom provjere odgode.';
                          this.infoDialogStyle=[{ width: '40rem' }];
                          this.displayInfoDialog= true;
                        
                      
                      }   
                    
                      this.isLoading = false;   
                    
                });

      
    },


    async dajMiSvePodatkeIgraca(){
      const baseUrl = localStorage.getItem("_rootRestUrl");

          let formData = JSON.stringify({"piramida":this.piramidaID});

          let config = {
            headers: {
              "Content-Type": "application/json",
          }};

          await axios
          .get(baseUrl + "sviPodaciIgraca.php?uuid="+this.$store.getters.useruuId,formData, config )
          .then((response) => {
                            
                this.sviPodaciIgraca = response.data; 

                this.ImeIgraca=this.sviPodaciIgraca['ime'];
                this.PrezimeIgraca=this.sviPodaciIgraca['prezime'];
                this.TelefonIgraca=this.sviPodaciIgraca['telefon'];
                this.EmailIgraca=this.sviPodaciIgraca['email'];
                this.unigraca=this.sviPodaciIgraca['username'];
                this.SpolIgraca=this.sviPodaciIgraca['spol'];
                this.PostanskiIgraca=this.sviPodaciIgraca['postanski'];
                this.UlicaIgraca=this.sviPodaciIgraca['adresa'];
                this.GradIgraca=this.sviPodaciIgraca['grad'];
                this.DrzavaIgraca=this.sviPodaciIgraca['drzava'];
                this.GodinaIgraca=this.sviPodaciIgraca['godina_rodenja'];
                this.slikaIgraca=this.sviPodaciIgraca['slika'];
                this.KlubIgraca=this.sviPodaciIgraca['teniski_klub'];
                this.URLSLIKEIGRACA = this.DOMENA+'/slike/'+this.slikaIgraca;
                this.displayDialogPromjenePodataka = true;
               
               
               

          })

          .catch((error) => {

                if(error.response){
                    this.InfoModalHeader='Greška';
                    this.InfoModalMessage='Došlo je do pogreške.';
                    this.infoDialogStyle=[{ width: '40rem' }];
                    this.displayInfoDialog= true;
                  
                
                }   
              
                this.isLoading = false;   
              
          });

    },

      async promjenaLozinke(){
        const baseUrl = localStorage.getItem("_rootRestUrl");

            var formData = new FormData();

            formData.append("uuid", this.$store.getters.useruuId);      
            formData.append("lozinka", CryptoJS.AES.encrypt(this.psigraca, "0x45a1f8d29b6a5c892714c76d58c394f95f37322f527a67d0b1d9831c00f4c78e").toString());
           

            await axios

              .post(baseUrl + "ajax_promjenaLozinke.php", formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              
              }
            
            )
            .then(() => {
                        this.InfoModalHeader='Info';
                        this.InfoModalMessage='Promjene su spremljene';
                        this.infoDialogStyle=[{ width: '40rem' }];
                        this.displayInfoDialog= true;
              }) 
              .catch((error) => {
                  alert('error'+error);

              });
        
      },

    async promjenaPodatakaIgraca(){


      const baseUrl = localStorage.getItem("_rootRestUrl");

        var formData = new FormData();
        
        formData.append("uuid", this.$store.getters.useruuId);      
        formData.append("ime",  this.ImeIgraca);
        formData.append("prezime", this.PrezimeIgraca);
        formData.append("telefon", this.TelefonIgraca);
        formData.append("email",this.EmailIgraca);
        formData.append("spol", this.SpolIgraca);
        formData.append("postanski", this.PostanskiIgraca);
        formData.append("adresa", this.UlicaIgraca);
        formData.append("grad",  this.GradIgraca);
        formData.append("drzava", this.DrzavaIgraca);
        formData.append("godina_rodenja",  this.GodinaIgraca);
        formData.append("teniski_klub",  this.KlubIgraca);
       
      await axios
        
          .post(baseUrl + "ajax_promjenapodataka.php", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          
           }
         
         )
         .then(() => {
                    this.InfoModalHeader='Info';
                    this.InfoModalMessage='Promjene su spremljene';
                    this.infoDialogStyle=[{ width: '40rem' }];
                    this.displayInfoDialog= true;
          }) 
          .catch((error) => {
              alert('error'+error);

           });

    },



    async uploadSlike(e){

     

      const baseUrl = localStorage.getItem("_rootRestUrl");

        var formData = new FormData();
               
        formData.append("uuid", this.$store.getters.useruuId);
        formData.append("fileField", e.files[0]);
       
      await axios
        
          .post(baseUrl + "ajax_uploadslike.php", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          
           }
         
         )
         .then(() => {
              this.dajMiSvePodatkeIgraca(); 
          }) 
          .catch((error) => {
              alert('error'+error);

           });

    },



    odabraoDatume(){
      this.okOdabraoDatume = true;
    },


    odgodiIgranje(){
        //provjeri jel minimalno 7 dana

        var odDana = moment(String(this.datumi[0]));
        var doDana = moment(String(this.datumi[1]));
       

        var danaOdgode = moment.duration(doDana.diff(odDana)).asDays();

        if(danaOdgode >= this.minimalniBrojDanaOdgode && danaOdgode <= this.maksimalniBrojDanaOdgode){
            //okej, može odgoditi
             odDana = moment(String(this.datumi[0])).format("YYYY-MM-DD");
             doDana = moment(String(this.datumi[1])).format("YYYY-MM-DD");
            
             this.odgodiIgranjeInDatabase(odDana, doDana);

        }else{
            this.displayDialogOdgodeGreskaOdabira=true;
            this.datumi=[];
         }


    },


   async odgodiIgranjeInDatabase(odDana,doDana){

      const baseUrl = localStorage.getItem("_rootRestUrl");

          let formData = JSON.stringify({
              odDana:odDana,
              doDana:doDana,
              uuid:this.$store.getters.useruuId,
              
          });

          let config = {
            headers: {
            "Content-Type": "application/json",
            }};

     await axios

          .post(baseUrl + "ajax_odgodaKola.php",formData, config )
          .then((response) => {

            if(response.data['ok']=='true'){
                this.displayDialogOdgode = false;
                this.displayInfoDialog=true;
                this.InfoModalMessage='Odgodili ste igranje';
                this.InfoModalHeader='Odgoda';
                this.$parent.dajPiramidu();
            }else{
                this.displayDialogOdgode = false;
                this.displayInfoDialog=true;
                this.InfoModalMessage='Greška prilikom odgode igranja!';
                this.InfoModalHeader='Odgoda';
            }

          })

          .catch(function () {
            // handle error
                        
            

          });

    },


    openCloseMenu() {
      this.$parent.sidebarColapse();
    },
  },

  emits: ["showSearchbar", "emitSearch"],
};
</script>

<style>
.text-in-circle {
  position: relative;
  display: inline-block;
  background: #138BA7;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  color: white;
  padding-top: 3px;
  padding-left: 0px;
}
.text-in-circle p {
  cursor: pointer;
}

.text-in-circle-icon {
  position: relative;
  display: inline-block;
  border: 1px solid #138BA7;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  /*color:white;*/
  padding-top: 3px;
  padding-left: 1px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 7px;
  color: black;
}

.w300 {
  width: 300px;
}

.dropdown-menu {
  top: 10px !important;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  /*  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);*/
}
.iconAsLink {
  text-decoration: none !important;
  font-size: 27px;
}
.topUserDropdownMenu .dropdown-menu a i {
  margin-right: 10px;
}
.dropdown-toggle::after {
  content: none !important;
}

.mOver:hover {
  color: #995555;
}

.topLoginBar {
  background-color: rgba(0,0,0,.1) !important;

  /* border-radius: 16px;*/
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.signInHref {
  color: white !important;
}
.signInHref:hover {
  color: #ddd !important;
}
.sidebarCollapseBtn {
  width: 50px;
  font-size:26px;
  margin-left: 2px;
}
#hereMoveSearch {
  padding-left: 90px;
  padding-right: 10px;
}

a:link {color:black !important}
a.signInHref{color:white !important}
.blueIcons {color:#138BA7 }

.razmakDolje{margin-bottom:14px}
.with100posto{width:90%;}

.p-dropdown-items-wrapper ul {padding:2px !important}
.p-icon.p-dropdown-check-icon{
  padding: 2px !important;
  margin: 0 ;
}

</style>